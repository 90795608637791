:root {
    --lightColor: azure;
    --linkHover: rgb(247, 58, 237);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

html,
body {
    height: 100vh;
    font-family: 'Poppins';
    color: var(--lightColor);
    cursor: url("./assets/GLOB.png") auto;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: grid;
    place-content: center;
}

.content {
    /* position: relative; */
    display: flex;
    gap: 5em;
    width: 100%;
    padding-top: 3em;
    position: relative;
}

.content::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 10%;
    right: 10%;
    width: 80%;
    border-bottom: 1px solid var(--lightColor);
    animation-name: opening;
    animation-duration: 4s;
}

@keyframes opening {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}


.linkGroup {
    display: flex;
    left: 10%;
    right: 10%;
    /* width: 50%; */
    gap: 1em;
}

h1 {
    font-size: 4rem;
    width: 50vw;
    line-height: 97%;
    text-align: right;
}

h1,
.link {
    flex-basis: 0;
    flex-grow: 1;
    /*clip-path: polygon(0 0,100% 0,100% 0,0 0);/*make text disapere*/
    /* animation-name:drop;
    animation-duration: 4s; */
}


.link {
    position: relative;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    border: solid 1px;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    padding: 0 .1em;
    text-decoration: none;
}

.link:hover {
    color: var(--linkHover);
    border: var(--linkHover);
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.2, 0 0 0.45em var(--linkHover));
    /*text shadow*/
}


/* @keyframes drop {
    from{translateY:(0px);}
    to{translateY:(10px);}    
} */

p {
    font-size: 1.3rem;
    width: 30vw;
}

a {
    text-decoration: none;


    &:visited {
        color: white;
    }


    &:hover {
        color: orange !important;
        cursor: pointer;
    }
}